import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormFileField,
  FormPanel,
  FormTextField,
  useFormSelector,
} from '@eas/common-web';
import { FormImageField } from '@components/form/image-field/form-image-field';
import { Attachment } from '@models';

export function AttachmentsReadDialog() {
  const values = useFormSelector((v: Attachment) => v);

  const isPDFAttachment = values?.file?.contentType?.includes('pdf');

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__ATTACHMENTS_TABLE__PANEL_TITLE__OVERVIEW"
            defaultMessage="Základné informácie"
          />
        }
      >
        <FormFileField
          name="file"
          label={
            <FormattedMessage
              id="ESD__ATTACHMENTS_TABLE__FIELD_LABEL__ATTACHMENT"
              defaultMessage="Príloha"
            />
          }
          required
          accept={[
            '.jpg',
            '.JPG',
            '.png',
            '.PNG',
            '.bmp',
            '.BMP',
            '.gif',
            '.GIF',
            '.svg',
            '.SVG',
            '.jpeg',
            '.JPEG',
            '.pdf',
            '.PDF',
          ]}
          disabled
        />
        <FormTextField
          name="description"
          label={
            <FormattedMessage
              id="ESD__ATTACHMENTS_TABLE__FIELD_LABEL__DESCRIPTION"
              defaultMessage="Popis"
            />
          }
          disabled
        />
      </FormPanel>

      {!isPDFAttachment && values?.file && (
        <FormImageField
          name="file"
          showFileField={false}
          //   label="Obrázok"
          height={400}
        />
      )}
    </>
  );
}
