/**
 * Journal entry permissions
 */
export enum PermissionJournalEntryBOZP {
  /**
   * Vloženie BOZP do denného záznamu
   */
  JOURNAL_ENTRY_BOZP_CREATE = 'JOURNAL_ENTRY_BOZP_CREATE',

  /**
   * Zmazanie BOZP denného záznamu
   */
  JOURNAL_ENTRY_BOZP_DELETE = 'JOURNAL_ENTRY_BOZP_DELETE',

  /**
   * žádost - Zmazanie BOZP denného záznamu
   */
  JOURNAL_ENTRY_BOZP_DELETE_REQUEST = 'JOURNAL_ENTRY_BOZP_DELETE_REQUEST',

  /**
   * Podpisovanie BOZP
   */
  JOURNAL_ENTRY_BOZP_SIGN = 'JOURNAL_ENTRY_BOZP_SIGN',
}
