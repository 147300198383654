import React, { useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormContext,
  FormTextField,
  PromptContext,
  useFormSelector,
  usePrompts,
} from '@eas/common-web';

const useUserFreetext = (name: string) => {
  const { testPrompt } = useContext(PromptContext);
  const { setFieldValue } = useContext(FormContext);

  const values = useFormSelector((v: any) => v);
  const user = values?.[name];

  const key = `freetext_${name}`;

  usePrompts(
    [
      {
        key,
        closeLabel: 'Prepnúť na číselník',
        confirmLabel: 'Potvrdiť freetext',
        dialogTitle: (
          <FormattedMessage
            id="ESD__FREETEXT__DIALOG_CONFIRM__TITLE"
            defaultMessage="Freetext"
          />
        ),
        dialogText: (
          <FormattedMessage
            id="ESD__FREETEXT__DIALOG_CONFIRM__TEXT"
            defaultMessage="Vyplňte formulár"
          />
        ),
        FormFields: () => {
          return (
            <>
              <FormTextField name="name" label="Jméno" />
              <FormTextField name="surname" label="Priezvisko" />
            </>
          );
        },
        formInitialValues: {
          name: user?.person?.name,
          surname: user?.person?.surname,
        },
        dialogDisableBackdrop: true,
      },
    ],
    [user?.person?.name, user?.person?.surname]
  );

  const handleTestPrompt = useCallback(
    (e) => {
      e.stopPropagation();

      testPrompt({
        key,
        submitCallback: (v) => {
          setFieldValue(name, {
            freeText: true,
            person: { ...v, freeText: true },
          });
        },
        closeCallback: () => {
          setFieldValue(name, null);
        },
      });
    },
    [key, testPrompt, setFieldValue, name]
  );

  const disabled = !!user?.freeText;

  return { handleTestPrompt, disabled };
};

export default useUserFreetext;
