import { useIntl } from 'react-intl';
import {
  DictionaryEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
} from '@eas/common-web';
import { useTenants } from '@modules/tenant/tenant-api';
import { WorkGroup } from '@models';

export const useColumns = () => {
  const intl = useIntl();

  const { dictionaryColumns } =
    DictionaryEvidence.useDictionaryColumns<WorkGroup>();

  return [
    {
      datakey: 'tenant',
      filterkey: 'tenant.id',
      sortkey: 'tenant.name',
      name: intl.formatMessage({
        id: 'ESD__WORK_GROUP__COLUMN__TENANT',
        defaultMessage: 'Vlastník',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory(useTenants),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'name',
      name: intl.formatMessage({
        id: 'ESD__WORK_GROUP__COLUMN__NAME',
        defaultMessage: 'Názov pracovnej skupiny',
      }),
      width: 250,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    ...dictionaryColumns,
  ] as TableColumn<WorkGroup>[];
};
