import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  AuthoredEvidence,
  EmptyComponent,
  Evidence,
  UserContext,
  getItemFactory,
} from '@eas/common-web';
import BozpDetailRemoveButton from '@modules/bozp/bozp-detail-remove-button';
import { Me, OtherRecord, PartialJournal, PrimaryJournal } from '@models';
import {
  EvidenceApiUrl,
  JournalEntryState,
  JournalTypeEnum,
  Permission,
} from '@enums';
import { useEvidencePermission } from '@utils/permission-hook';
import useDetailSubtitle from '@utils/use-detail-subtitle';
import { useUserContextSwitch } from '@utils/user-context-switch';
import { BozpActionbar } from './other-record-actionbar';
import { Fields } from './other-record-fields';
import { useOtherRecordsHookedSources } from './other-record-hooked-sources';
import { useValidationSchema } from './other-record-schema';
import { useColumns } from './other-records-columns';

export const OtherRecords = () => {
  const { user } = useContext<UserContext<Me>>(UserContext);
  const intl = useIntl();

  const { handleSwitchSupplier } = useUserContextSwitch();
  const validationSchema = useValidationSchema();
  const subtitle = useDetailSubtitle();

  const permissions = useEvidencePermission({
    options: {
      NEW: [],
      EDIT: [Permission.JournalEntry.JOURNAL_ENTRY_UPDATE],
      REMOVE: [],
      REFRESH: [Permission.JournalEntry.JOURNAL_ENTRY_DETAIL],
      SHARE: [Permission.JournalEntry.JOURNAL_ENTRY_DETAIL],
      CHECK: [Permission.JournalEntry.JOURNAL_ENTRY_UPDATE],
      SAVE: [Permission.JournalEntry.JOURNAL_ENTRY_UPDATE],
      CLOSE: [Permission.JournalEntry.JOURNAL_ENTRY_UPDATE],
    },
  });

  const evidence = AuthoredEvidence.useAuthoredEvidence<OtherRecord>({
    version: 1,
    identifier: 'OTHER_RECORDS',
    switcherProps: { midPoint: 0 },
    apiProps: {
      url: EvidenceApiUrl.JOURNAL_ENTRY_OTHER_RECORDS,
      getItem: getItemFactory<OtherRecord>({
        postProcess: (record) => {
          const journal = record?.journalEntry?.journal;
          const journalType = record?.journalEntry?.journal?.journalType;

          if (journalType === JournalTypeEnum.PARTIAL) {
            const partialJournal = journal as PartialJournal;

            handleSwitchSupplier(partialJournal.supplier?.id);
          } else if (journalType === JournalTypeEnum.PRIMARY) {
            const primaryJournal = journal as PrimaryJournal;

            handleSwitchSupplier(primaryJournal?.contractor?.id);
          }

          return record;
        },
      }),
      hookedSources: useOtherRecordsHookedSources,
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__OTHER_RECORDS__TITLE',
        defaultMessage: 'Ostatné záznamy',
      }),
      ToolbarComponent: EmptyComponent,
    },
    detailProps: {
      FieldsComponent: Fields,
      validationSchema,
      lock: (v) => {
        if (v?.journalEntry?.state !== JournalEntryState.OPENED)
          return 'Denný záznam nie je otvorený.';

        if (v?.signed)
          return 'Záznam je podpísaný. Možno iba pridávať osoby a podpisovať.';

        return false;
      },
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__OTHER_RECORDS__TITLE',
          defaultMessage: 'Ostatné záznamy',
        }),
        disableBtn: (button) =>
          ['NEW', 'EDIT'].includes(button) &&
          (!user?.supplier || !user?.tenant),
        showBtn: permissions,
        ActionBar: BozpActionbar,
        subtitle,
        After: () => (
          <BozpDetailRemoveButton
            type="OTHER_RECORDS"
            directDeletePermission={
              Permission.JournalEntryBOZP.JOURNAL_ENTRY_BOZP_DELETE
            }
            requestDeletePermission={
              Permission.JournalEntryBOZP.JOURNAL_ENTRY_BOZP_DELETE_REQUEST
            }
          />
        ),
      },
    },
  });

  return <Evidence {...evidence} />;
};
