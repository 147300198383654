import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  FormAutocomplete,
  FormPanel,
  FormTextField,
} from '@eas/common-web';
import { useTenants } from '@modules/tenant/tenant-api';
import { WorkGroupField } from '@composite/work-group-collection/work-group-field';

export const Fields = () => {
  const { isExisting } = useContext(DetailContext);
  const tenants = useTenants();

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__WORK_GROUP__DETAIL__PANEL_TITLE__OVERVIEW"
            defaultMessage="Základné informácie"
          />
        }
      >
        <FormAutocomplete
          name="tenant"
          source={tenants}
          label={
            <FormattedMessage
              id="ESD__WORK_GROUP__DETAIL__FIELD_LABEL__TENANT"
              defaultMessage="Vlastník"
            />
          }
          disabled
          required
        />
        <FormTextField
          name="name"
          label={
            <FormattedMessage
              id="ESD__WORK_GROUP__DETAIL__FIELD_LABEL__NAME"
              defaultMessage="Názov pracovnej skupiny"
            />
          }
          required
        />
      </FormPanel>

      {isExisting && <WorkGroupField />}
    </>
  );
};
