import React from 'react';
import { useIntl } from 'react-intl';
import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';
import { Redirect } from '@components/redirect/redirect';
import { EvidenceBrowserUrl } from '@enums';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'id',
      name: 'Odkaz',
      width: 80,
      CellComponent: (props) => (
        <Redirect
          url={EvidenceBrowserUrl.JOURNAL_ENTRY_BOZP}
          id={props.rowValue!.id}
        />
      ),
    },
    {
      datakey: 'header',
      name: intl.formatMessage({
        id: 'ESD__BOZP_COLLECTION__TABLE__COLUMN__HEADER',
        defaultMessage: 'Hlavička',
      }),
      width: 200,
    },
    {
      datakey: 'signed',
      name: intl.formatMessage({
        id: 'ESD__BOZP__TABLE__COLUMN__HEADER',
        defaultMessage: 'Podpísané',
      }),
      width: 80,
      CellComponent: TableFieldCells.BooleanCell,
    },
    /*    {
      datakey: 'journalEntry.state',
      name: intl.formatMessage({
        id: 'ESD__BOZP__TABLE__COLUMN__CAN_EDIT',
        defaultMessage: 'Možno editovať',
      }),
      width: 120,
      CellComponent: function Cell(props) {
        console.log(props);
        const canEdit = props.value === JournalEntryState.OPENED;

        return <TableFieldCells.BooleanCell {...props} value={canEdit} />;
      },
    }, */
    {
      datakey: 'created',
      name: intl.formatMessage({
        id: 'ESD__BOZP_COLLECTION__TABLE__COLUMN__CREATED',
        defaultMessage: 'Vytvořeno',
      }),
      width: 200,
      CellComponent: TableFieldCells.DateTimeCell,
    },
    {
      datakey: 'createdBy.name',
      name: intl.formatMessage({
        id: 'ESD__NOTES_COLLECTION__COLUMN__CREATED',
        defaultMessage: 'Autor',
      }),
      width: 200,
    },
  ];
}
