import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AuthoredEvidence,
  DictionaryEvidence,
  FormAutocomplete,
  FormTextField,
  UserContext,
} from '@eas/common-web';
import { useWorkCategories } from '@modules/dict__work-category/work-category-api';
import { useWorkTbpps } from '@modules/dict__work-tbpp/work-tbpp-api';
import { useTenants } from '@modules/tenant/tenant-api';
import { Me } from '@models';

export const Fields = () => {
  const { user } = useContext<UserContext<Me>>(UserContext);

  const tenants = useTenants();
  const workCategories = useWorkCategories();
  const tbpps = useWorkTbpps();

  const showTBPP = user?.tenant?.code !== 'ZSD';

  return (
    <>
      <DictionaryEvidence.FieldOrder />
      <FormAutocomplete
        name="tenant"
        source={tenants}
        label={
          <FormattedMessage
            id="ESD__MECHANISMS__DETAIL__FIELD_LABEL__TENANT"
            defaultMessage="Vlastník"
          />
        }
        disabled
      />
      <FormAutocomplete
        name="category"
        source={workCategories}
        label={
          <FormattedMessage
            id="ESD__WORK__DETAIL__FIELD_LABEL__CATEGORY"
            defaultMessage="Kategória práce"
          />
        }
        required
      />
      <FormTextField
        name="name"
        label={
          <FormattedMessage
            id="ESD__WORK__DETAIL__FIELD_LABEL__NAME"
            defaultMessage="Práca"
          />
        }
        required
      />
      <FormTextField
        name="units"
        label={
          <FormattedMessage
            id="ESD__WORK__DETAIL__FIELD_LABEL__UNITS"
            defaultMessage="Jednotky práce"
          />
        }
        required
      />
      {showTBPP && (
        <FormAutocomplete
          name="tbpp"
          source={tbpps}
          label={
            <FormattedMessage
              id="ESD__WORK__DETAIL__FIELD_LABEL__TBPP"
              defaultMessage="TBPP"
            />
          }
        />
      )}
      <DictionaryEvidence.FieldActive />
      <AuthoredEvidence.AuthoredFields />
    </>
  );
};
