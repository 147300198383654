import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormNumberField,
  FormPanel,
  FormSelect,
  FormTextField,
  UserContext,
  eqFilterParams,
  useFormSelector,
} from '@eas/common-web';
import { useWorkCategories } from '@modules/dict__work-category/work-category-api';
import { useWorkTbpps } from '@modules/dict__work-tbpp/work-tbpp-api';
import { useWorks } from '@modules/dict__work/work-api';
import { FormFreeTextFieldParentState } from '@components/form/form-free-text-field/form-free-text-field-parent-state';
import { Me, WorkDone } from '@models';
import { useAssemblyTypes } from '../work-done-api';

export function WorkDoneCreateDialog() {
  const { user } = useContext<UserContext<Me>>(UserContext);

  const { category, type } = useFormSelector((values: WorkDone) => ({
    category: values?.type?.category,
    type: values.type,
  }));
  const [isFreeText, setIsFreeText] = useState(!!type?.freeText);

  const workCategories = useWorkCategories();
  const works = useWorks(
    category
      ? eqFilterParams({ field: 'category.id', value: category.id })
      : undefined
  );
  const assemblyTypes = useAssemblyTypes();
  const tbpps = useWorkTbpps();

  const showTBPP = user?.tenant?.code !== 'ZSD';

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__WORK_DONE__COLLECTION__PANEL_LABEL__BASIC"
            defaultMessage="Základné"
          />
        }
      >
        <FormFreeTextFieldParentState
          isFreeText={isFreeText}
          setIsFreetext={setIsFreeText}
          autocompleteProps={{
            source: workCategories,
            label: 'Kategória práce',
            name: 'type.category',
          }}
          textFieldProps={{
            name: 'type.category.name',
            label: 'Kategórie práce - manuálne',
          }}
          fieldsToReset={['type']}
        />
        <FormFreeTextFieldParentState
          isFreeText={isFreeText}
          setIsFreetext={setIsFreeText}
          autocompleteProps={{
            source: works,
            label: 'Práce',
            name: 'type',
          }}
          textFieldProps={{
            name: 'type.name',
            label: 'Práce - manuálne',
          }}
          fieldsToReset={['type']}
        />

        {/*   <FormAutocomplete
          name="type"
          source={works}
          label={
            <FormattedMessage
              id="ESD__WORK_DONE__COLLECTION__FIELD_LABEL__TYPE"
              defaultMessage="Práca"
            />
          }
          required
        /> */}
        <FormSelect
          source={assemblyTypes}
          name="assemblyType"
          label={
            <FormattedMessage
              id="ESD__WORK_DONE__COLLECTION__FIELD_LABEL__ASSEMBLY_TYPE"
              defaultMessage="Druh práce"
            />
          }
          valueIsId
        />
        <FormTextField
          name="type.units"
          label={
            <FormattedMessage
              id="ESD__WORK_DONE__COLLECTION__FIELD_LABEL__WORK_UNITS"
              defaultMessage="Merná jednotka"
            />
          }
          disabled={!isFreeText}
        />
        <FormNumberField
          name="amount"
          label={
            <FormattedMessage
              id="ESD__WORK_DONE__COLLECTION__FIELD_LABEL__AMOUNT"
              defaultMessage="Počet vykonanej práce za daný den"
            />
          }
        />
        <FormTextField
          name="section"
          label={
            <FormattedMessage
              id="ESD__WORK_DONE__COLLECTION__FIELD_LABEL__SECTION"
              defaultMessage="Úsek"
            />
          }
        />
        {showTBPP && (
          <FormFreeTextFieldParentState
            isFreeText={isFreeText}
            setIsFreetext={setIsFreeText}
            autocompleteProps={{
              source: tbpps,
              label: 'TBPP',
              name: 'type.tbpp',
              disabled: true,
            }}
            textFieldProps={{
              name: 'type.tbpp.name',
              label: 'TBPP - manuálne',
            }}
            fieldsToReset={['type']}
          />
        )}
      </FormPanel>
    </>
  );
}
