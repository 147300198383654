import React, { useContext } from 'react';
import IconButton from '@material-ui/core/IconButton';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import {
  AutocompleteSource,
  DetailContext,
  DetailMode,
  FormAutocomplete,
  Tooltip,
} from '@eas/common-web';
import { userLabelMapper } from '@utils/label-mapper';
import useUserFreetext from './use-user-freetext';

const UserFreeTextButton = ({
  handleTestPrompt,
}: {
  handleTestPrompt: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  const { mode } = useContext(DetailContext);

  if (mode !== DetailMode.EDIT && mode !== DetailMode.NEW) return null;

  return (
    <Tooltip title="Zadať ako freetext">
      <IconButton
        onClick={handleTestPrompt}
        style={{
          marginRight: '2pt',
          padding: 1,
        }}
      >
        <AutorenewIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

const UserFreetextField = ({
  autocompleteSource,
  name,
  label,
}: {
  autocompleteSource: AutocompleteSource<any>;
  name: string;
  label: React.ReactNode;
}) => {
  const { handleTestPrompt, disabled } = useUserFreetext(name);

  return (
    <FormAutocomplete
      name={name}
      source={autocompleteSource}
      labelMapper={userLabelMapper}
      label={label}
      required
      disabled={disabled}
      after={<UserFreeTextButton handleTestPrompt={handleTestPrompt} />}
    />
  );
};

export default UserFreetextField;
