import React from 'react';
import { FormFileField } from '@eas/common-web';

export function DocumentsDialog() {
  return (
    <>
      <FormFileField name="file" label="Dokument" />
    </>
  );
}
