import { useContext } from 'react';
import {
  ApiFilterOperation,
  HookedSource,
  UserContext,
  useScrollableSource,
} from '@eas/common-web';
import { Bozp, JournalEntry, Me } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';

export const INSTRUCTED_PERSON = 'INSTRUCTED_PERSON';
export const FILES = 'FILES';

function useInstructedPersonSource(): HookedSource<Bozp> {
  const source = useScrollableSource();

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  return {
    [INSTRUCTED_PERSON]: {
      source,
      shouldLoad: () =>
        hasPermission(Permission.JournalEntry.JOURNAL_ENTRY_DETAIL),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.JOURNAL_ENTRY_BOZP}/person/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'bozp.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}
function useBozpFilesSource(): HookedSource<Bozp> {
  const source = useScrollableSource();

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  return {
    [FILES]: {
      source,
      shouldLoad: () =>
        hasPermission(Permission.JournalEntry.JOURNAL_ENTRY_DETAIL),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.JOURNAL_ENTRY_BOZP}/file/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'bozp.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

export function useBozpHookedSources(): HookedSource<Bozp> {
  const instructedPersons = useInstructedPersonSource();
  const files = useBozpFilesSource();

  return {
    ...instructedPersons,
    ...files,
  };
}
