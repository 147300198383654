/**
 * Dielčí denník - stav
 */
export enum PartialJournalState {
  /**
   * Vytvorený
   */
  CREATED = 'CREATED',

  /**
   * Otvorený
   */
  OPENED = 'OPENED',

  /**
   * Znovuotvorený
   */
  REOPENED = 'REOPENED',

  /**
   * Uzavretý
   */
  CLOSED = 'CLOSED',

  /**
   * Podpísaný
   */
  SIGNED = 'SIGNED',

  /**
   * Zrušený
   */
  CANCELLED = 'CANCELLED',
}
