import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { regionApiErrors } from 'src/enums/messages/api-errors';
import { v4 as uuidv4 } from 'uuid';
import {
  DictionaryEvidence,
  EmptyComponent,
  Evidence,
  SnackbarContext,
  UserContext,
} from '@eas/common-web';
import { DictionaryDeleteAllActionBar } from '@components/dictionary-delete-all-actionbar/dictionary-delete-all-actionbar';
import { MultipleDeleteTableToolbarButton } from '@components/multiple-delete-table-toolbar-button/multiple-delete-table-toolbar-button';
import { TableToolbarImportButtons } from '@components/table-toolbar-import-buttons/table-toolbar-import-buttons';
import { TakeoverTableToolbarButton } from '@components/takeover-table-toolbar-button/takeover-table-toolbar-button';
import { Me, MechanismCategory } from '@models';
import {
  EvidenceApiUrl,
  EvidenceBrowserUrl,
  ExportTag,
  Permission,
} from '@enums';
import { handleApiErrors } from '@utils/error-handler';
import { useEvidencePermission } from '@utils/permission-hook';
import { useColumns } from './mechanism-category-columns';
import { Fields } from './mechanism-category-fields';
import { useValidationSchema } from './mechanism-category-schema';

export function MechanismCategories() {
  const intl = useIntl();
  const { user } = useContext<UserContext<Me>>(UserContext);

  const validationSchema = useValidationSchema();

  const { showSnackbar } = useContext(SnackbarContext);

  const permissions = useEvidencePermission({
    options: {
      NEW: [Permission.MechanismCategory.MECHANISM_CATEGORY_CREATE],
      EDIT: [Permission.MechanismCategory.MECHANISM_CATEGORY_UPDATE],
      REMOVE: [Permission.MechanismCategory.MECHANISM_CATEGORY_DELETE],
      REFRESH: [Permission.MechanismCategory.MECHANISM_CATEGORY_DETAIL],
      SHARE: [Permission.MechanismCategory.MECHANISM_CATEGORY_DETAIL],
      CHECK: [
        Permission.MechanismCategory.MECHANISM_CATEGORY_CREATE,
        Permission.MechanismCategory.MECHANISM_CATEGORY_UPDATE,
      ],
      SAVE: [
        Permission.MechanismCategory.MECHANISM_CATEGORY_CREATE,
        Permission.MechanismCategory.MECHANISM_CATEGORY_UPDATE,
      ],
      CLOSE: [
        Permission.MechanismCategory.MECHANISM_CATEGORY_CREATE,
        Permission.MechanismCategory.MECHANISM_CATEGORY_UPDATE,
      ],
    },
  });

  const defaultMechanismCategory: MechanismCategory = {
    id: uuidv4(),
    name: '',
    tenant: user?.tenant,
  };

  const evidence = DictionaryEvidence.useDictionaryEvidence<MechanismCategory>({
    version: 3,
    identifier: 'MECHANISM_CATEGORY',
    apiProps: {
      url: EvidenceApiUrl.MECHANISM_CATEGORY,
      ...handleApiErrors(regionApiErrors.MECHANISM_CATEGORY, showSnackbar),
    },

    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__MECHANISM_CATEGORY__TABLE__TITLE',
        defaultMessage: 'Kategória mechanizmu',
      }),
      reportTag: ExportTag.MECHANISM_CATEGORY_GRID,
      showRefreshButton: false,
      toolbarProps: {
        after: (
          <>
            <TableToolbarImportButtons
              importPermissionKey={
                Permission.MechanismCategory.MECHANISM_CATEGORY_IMPORT
              }
            />
            <TakeoverTableToolbarButton
              takeoverPermissionKey={
                Permission.MechanismCategory.MECHANISM_CATEGORY_TAKEOVER
              }
            />
            {/*       <MultipleDeleteTableToolbarButton
              deletePermissionKey={
                Permission.MechanismCategory.MECHANISM_CATEGORY_DELETE
              }
            /> */}
          </>
        ),
      },
    },
    detailProps: {
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      validationSchema,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__MECHANISM_CATEGORY__DETAIL__TITLE',
          defaultMessage: 'Správa kategórií mechanizmov',
        }),
        disableBtn: (button) =>
          ['NEW', 'EDIT'].includes(button) &&
          (!user?.supplier || !user?.tenant),
        showBtn: permissions,
        ActionBar: () => (
          <DictionaryDeleteAllActionBar
            removeAllPermission={
              Permission.MechanismCategory.MECHANISM_CATEGORY_DELETE
            }
            navigateBackUrl={EvidenceBrowserUrl.MECHANISM_CATEGORY}
          />
        ),
      },
      initNewItem: () => defaultMechanismCategory,
    },
  });

  return <Evidence {...evidence} />;
}
