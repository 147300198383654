import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { regionApiErrors } from 'src/enums/messages/api-errors';
import { v4 as uuidv4 } from 'uuid';
import {
  DictionaryEvidence,
  EmptyComponent,
  Evidence,
  SnackbarContext,
  UserContext,
} from '@eas/common-web';
import { DictionaryDeleteAllActionBar } from '@components/dictionary-delete-all-actionbar/dictionary-delete-all-actionbar';
import { MultipleDeleteTableToolbarButton } from '@components/multiple-delete-table-toolbar-button/multiple-delete-table-toolbar-button';
import { TableToolbarImportButtons } from '@components/table-toolbar-import-buttons/table-toolbar-import-buttons';
import { TakeoverTableToolbarButton } from '@components/takeover-table-toolbar-button/takeover-table-toolbar-button';
import { Absence, Me } from '@models';
import {
  EvidenceApiUrl,
  EvidenceBrowserUrl,
  ExportTag,
  Permission,
} from '@enums';
import { handleApiErrors } from '@utils/error-handler';
import { useEvidencePermission } from '@utils/permission-hook';
import { useColumns } from './absence-columns';
import { Fields } from './absence-fields';
import { useValidationSchema } from './absence-schema';

export const Absences = () => {
  const intl = useIntl();
  const { user } = useContext<UserContext<Me>>(UserContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const validationSchema = useValidationSchema();

  const permissions = useEvidencePermission({
    options: {
      NEW: [Permission.Absence.ABSENCE_CREATE],
      EDIT: [Permission.Absence.ABSENCE_UPDATE],
      REMOVE: [Permission.Absence.ABSENCE_DELETE],
      REFRESH: [Permission.Absence.ABSENCE_DETAIL],
      SHARE: [Permission.Absence.ABSENCE_DETAIL],
      CHECK: [
        Permission.Absence.ABSENCE_CREATE,
        Permission.Absence.ABSENCE_UPDATE,
      ],
      SAVE: [
        Permission.Absence.ABSENCE_CREATE,
        Permission.Absence.ABSENCE_UPDATE,
      ],
      CLOSE: [
        Permission.Absence.ABSENCE_CREATE,
        Permission.Absence.ABSENCE_UPDATE,
      ],
      INVALIDATE: [Permission.Absence.ABSENCE_INVALIDATE],
      REVALIDATE: [Permission.Absence.ABSENCE_REVALIDATE],
    },
  });

  const defaultAbsence: Absence = {
    id: uuidv4(),
    name: '',
    tenant: user?.tenant,
  };

  const evidence = DictionaryEvidence.useDictionaryEvidence({
    version: 3,
    identifier: 'ABSENCE',
    apiProps: {
      url: EvidenceApiUrl.ABSENCE,
      ...handleApiErrors(regionApiErrors.ABSENCE, showSnackbar),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__ABSENCE__TABLE__TITLE',
        defaultMessage: 'Neprítomnosti',
      }),
      reportTag: ExportTag.ABSENCE_GRID,
      showRefreshButton: false,
      toolbarProps: {
        after: (
          <>
            <TableToolbarImportButtons
              importPermissionKey={Permission.Absence.ABSENCE_IMPORT}
            />
            <TakeoverTableToolbarButton
              takeoverPermissionKey={Permission.Absence.ABSENCE_TAKEOVER}
            />
            {/*    <MultipleDeleteTableToolbarButton
              deletePermissionKey={Permission.Absence.ABSENCE_DELETE}
            /> */}
          </>
        ),
      },
    },
    detailProps: {
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      validationSchema,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__ABSENCE__DETAIL__TITLE',
          defaultMessage: 'Správa neprítomností',
        }),
        disableBtn: (button) =>
          ['NEW', 'EDIT'].includes(button) &&
          (!user?.supplier || !user?.tenant),
        showBtn: permissions,
        ActionBar: () => (
          <DictionaryDeleteAllActionBar
            removeAllPermission={Permission.Absence.ABSENCE_DELETE}
            navigateBackUrl={EvidenceBrowserUrl.ABSENCE}
          />
        ),
      },
      initNewItem: () => defaultAbsence,
    },
  });

  return <Evidence {...evidence} />;
};
