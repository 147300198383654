import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  DetailMode,
  EvidenceContext,
  UserContext,
} from '@eas/common-web';
import { BOZP } from '@modules/journal-entry/journal-entry-hooked-sources';
import { CollectionField2 } from '@components/collection-field/collection-field';
import { Me } from '@models';
import { EvidenceApiUrl } from '@enums';
import { useColumns } from './bozp-colums';
import { useValidationSchema } from './bozp-schema';
import { BOZPCreateDialog } from './dialogs/new-bozp-create-dialog';

export function BozpField() {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);
  const {
    source: { data },
    isLocked,
    mode,
  } = useContext(DetailContext);
  const { hookedSources } = useContext(EvidenceContext);

  const source = hookedSources[BOZP]?.source;

  return (
    <CollectionField2
      title={
        <FormattedMessage id="ESD__FIELD_LABEL__BOZP" defaultMessage="BOZP" />
      }
      source={source}
      useColumns={useColumns}
      disabled={isLocked || mode !== DetailMode.VIEW}
      createAction={{
        getUrl: () => EvidenceApiUrl.JOURNAL_ENTRY + '/bozp',
        Dialog: BOZPCreateDialog,
        initValues: () => ({
          journalEntry: { id: data.id },
        }),
        useSchema: useValidationSchema,
        dialogWidth: 650,
        label: 'Pridať',
      }}
      readAction={{
        getUrl: (collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/bozp/${collectionId}`,
      }}
      updateAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/bozp/${collectionId}`,
        hide: true,
      }}
      deleteAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/bozp/${collectionId}`,
        label: 'Odobrať',
        hide: true,
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/bozp/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/bozp/${collectionId}`,
      }}
    />
  );
}
